import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper, H2 } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/student-agency/logoSA.png"
import SA from "../../../images/reference/student-agency/sa.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"

class Student extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Individuální školení pro STUDENT AGENCY | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceHeader
          companyName="STUDENT AGENCY"
          heading="Individuální školení pro STUDENT AGENCY"
          date="září 2014 - únor 2015"
        />

        <ReferenceImageText
          right
          img={SA}
          eager
          alt=""
          logo={Logo}
          logoAlt="logo společnosti Student Agency"
          link="//student-agency.cz/"
        >
          <p>
            STUDENT AGENCY je největší cestovní agentura v České republice,
            největší prodejce letenek i jízdenek na trhu a největší agentura pro
            prodej jazykových a pracovních pobytů v zahraničí.
          </p>
          <PaddingWrapper>
            <h2>Zadání</h2>
            <p>
              Ukázat marketingovému týmu STUDENT AGENCY novinky v online
              marketingu a přinést čerstvé nápady zvenčí.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <ReferenceImageText>
          <PaddingWrapper>
            <H2>Rozsah projektu</H2>
            <ul>
              <li>brandová komunikace</li>
              <li>tvorba marketingových kampaní</li>
              <li>SEO</li>
              <li>PPC</li>
            </ul>
          </PaddingWrapper>

          <PaddingWrapper>
            <H2>Historie spolupráce</H2>
            <p>
              Vzhledem k šířce záběru témat, kterými chtěl klient se svým týmem
              projít, jsme školení rozdělili do série workshopů. Ty probíhaly od
              září 2014 do února 2015. Provedli jsme inhouse marketingový tým
              STUDENT AGENCY procesem tvorby online kampaní, brandingem i
              novinkami v PPC a SEO za poslední léta. U všech témat jsme kladli
              důraz na možnost okamžitého využití. Nepřednášeli jsme pouze
              teoretické základy, ale vše ukazovali na konkrétních
              příležitostech.
            </p>
          </PaddingWrapper>
        </ReferenceImageText>

        <Medailon
          personName="Martin Chomča"
          position="HR Generalista"
          img={Logo}
        >
          <p>
            Veškeré marketingové aktivity si řídíme sami, proto je pro nás
            nezbytné získat občas nový pohled na věc - mimo naši bublinu.
          </p>
        </Medailon>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Student
